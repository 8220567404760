<template>
  <div
    ref="pdfSection"
    class="j-page-terms-conditions"
  >
    <div class="j-page-terms-conditions__section mt-lg-16 pt-lg-6 mt-3 d-flex justify-space-between flex-wrap">
      <general-text
        :text-types="TEXT_TYPES.pageTitle"
        dictionary-key="terms_and_conditions_title"
        class="j-text--white mr-2"
      />
      <div>
        <general-text
          dictionary-key="terms_and_conditions_version_update"
          class="j-text--white mb-lg-2"
        />
        <div class="d-flex">
          <v-btn
            variant="text"
            class="j-btn j-btn--no-hover j-btn--no-active text-none text-button font-weight-regular pa-0 mr-4"
            min-width="initial"
            height="initial"
            :ripple="false"
            @click="printPage"
          >
            {{ $t('general_print') }}
            <v-icon
              :size="16"
              icon="custom:printIcon"
              class="ml-2"
            />
          </v-btn>
          <v-btn
            variant="text"
            class="j-btn j-btn--no-hover j-btn--no-active text-none text-button font-weight-regular pa-0"
            min-width="initial"
            height="initial"
            :ripple="false"
            @click="downloadPage"
          >
            {{ $t('general_download') }}
            <v-icon
              :size="16"
              icon="custom:downloadIcon"
              class="ml-2"
            />
          </v-btn>
        </div>
      </div>
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_introduction_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(introText, index ) of addIndexForLocalization('terms_and_conditions_introduction_text_items')"
      :key="`intro_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :dictionary-key="introText.dictionaryKey" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_license_information_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(licenceText, index )
        in addIndexForLocalization('terms_and_conditions_license_information_text_items')"
      :key="`licence_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :dictionary-key="licenceText.dictionaryKey" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_terms_change_rules_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(rulesText, index )
        in addIndexForLocalization('terms_and_conditions_change_rules_text_items')"
      :key="`rules_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :dictionary-key="rulesText.dictionaryKey" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_legal_requirements_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(requireText, index )
        in addIndexForLocalization('terms_and_conditions_legal_requirements_text_items')"
      :key="`require_text_${index}`"
    >
      <div
        v-if="index === 4"
        class="j-page-terms-conditions__section pt-5"
      >
        <general-text-list
          :items-list="bannedCountries"
          :column-count="columnCountForMobile"
          text-list-items
        />
      </div>
      <div
        v-if="index === 4"
        class="j-page-terms-conditions__section mt-13 mb-6"
      >
        <general-text dictionary-key="terms_and_conditions_legal_requirements_text_after_table_items" />
      </div>
      <div class="j-page-terms-conditions__section mb-6">
        <general-text :dictionary-key="requireText.dictionaryKey" />
      </div>
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_kyc_policy_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(amlOneText, index) of amlPolicyText.partOneText"
      :key="`aml_one_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :text="amlOneText" />
    </div>
    <div class="j-page-terms-conditions__section mb-6">
      <general-text-list
        :items-list="amlPolicyText.partOneTextList"
        text-list-items
      />
    </div>
    <div
      v-for="(amlTwoText, index) of amlPolicyText.partTwoText"
      :key="`aml_two_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :text="amlTwoText" />
    </div>
    <div class="j-page-terms-conditions__section mb-6">
      <general-text-list
        :items-list="amlPolicyText.partTwoTextList"
        text-list-items
      />
    </div>
    <div class="j-page-terms-conditions__section mb-6">
      <general-text dictionary-key="terms_and_conditions_kyc_policy_text_part_three" />
    </div>
    <div class="j-page-terms-conditions__section mb-6">
      <general-text-list
        :items-list="amlPolicyText.partThreeTextList"
        text-list-items
      />
    </div>
    <div
      v-for="(amlFourText, index) of amlPolicyText.partFourText"
      :key="`aml_four_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :text="amlFourText" />
    </div>
    <div class="j-page-terms-conditions__section mb-6">
      <general-text-list
        :items-list="strategicRestrictionCountries"
        :column-count="2"
        text-list-items
      />
    </div>
    <div
      v-for="(amlFiveText, index) of amlPolicyText.partFiveText"
      :key="`aml_five_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :text="amlFiveText" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_registration_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(registrationText, index )
        in addIndexForLocalization('terms_and_conditions_registration_items_text')"
      :key="`licence_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :dictionary-key="registrationText.dictionaryKey" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_personal_information_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(personalInfo, index )
        in addIndexForLocalization('terms_and_conditions_personal_information_items_text')"
      :key="`personal_info${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :dictionary-key="personalInfo.dictionaryKey" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_security_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(securityText, index )
        in addIndexForLocalization('terms_and_conditions_security')"
      :key="`security_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :dictionary-key="securityText.dictionaryKey" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_payment_policy_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(paymentOneText, index) of paymentPolicyText.textOne"
      :key="`payment_one_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :text="paymentOneText" />
    </div>
    <div class="j-page-terms-conditions__section mt-14 mb-16 text-center">
      <general-table
        :headers="TABLES_ITEMS.paymentsPolicy.headers"
        :items="TABLES_ITEMS.paymentsPolicy.userData"
        disable-adaptivity
      />
    </div>
    <div
      v-for="(paymentTwoText, index) of paymentPolicyText.textTwo"
      :key="`payment_two_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :text="paymentTwoText" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_block_account"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(blockAccountText, index )
        in addIndexForLocalization('terms_and_conditions_block_account_items_text')"
      :key="`block_account_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :dictionary-key="blockAccountText.dictionaryKey" />
    </div>
    <div class="j-page-terms-conditions__section pt-5">
      <general-text-list :items-list="addIndexForLocalization('terms_and_conditions_block_account_items_list')" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_bonus_policy"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(bonusOneText, index) of bonusPolicyText.partOneText"
      :key="`bonus_one_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :text="bonusOneText" />
    </div>
    <div class="j-page-terms-conditions_section text-center mb-6">
      <general-table
        :headers="TABLES_ITEMS.userStatus.headers"
        :items="TABLES_ITEMS.userStatus.itemsList"
        disable-adaptivity
      />
    </div>
    <div
      v-for="(bonusTwoText, index) of bonusPolicyText.partTwoText"
      :key="`bonus_two_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :text="bonusTwoText" />
    </div>
    <div class="j-page-terms-conditions_section text-center mb-6">
      <general-table
        :headers="TABLES_ITEMS.statusDetails.headers"
        :items="TABLES_ITEMS.statusDetails.itemsList"
        disable-adaptivity
      />
    </div>
    <div
      v-for="(bonusThreeText, index) of bonusPolicyText.partThreeText"
      :key="`bonus_three_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :text="bonusThreeText" />
    </div>
    <div class="j-page-terms-conditions_section mb-6">
      <general-text-list
        :items-list="bonusPolicyText.partThreeTextList"
        text-list-items
        :column-count="2"
      />
    </div>
    <div class="j-page-terms-conditions_section mb-6">
      <general-text dictionary-key="terms_and_conditions_bonus_policy_text_part_four" />
    </div>
    <div class="j-page-terms-conditions_section mb-6">
      <general-text-list
        :items-list="GAMES_WAGERING_ONE_PERCENT"
        :column-count="2"
        text-list-items
      />
    </div>
    <div
      v-for="(bonusFiveText, index) of bonusPolicyText.partFiveText"
      :key="`bonus_five_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :text="bonusFiveText" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_responsible_game_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(responsibleGameText, index )
        in addIndexForLocalization('terms_and_conditions_responsible_game_items_text')"
      :key="`responsibleGame_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :dictionary-key="responsibleGameText.dictionaryKey" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_changes_website_title"
        class="j-text--main-gradient"
      />
    </div>
    <div class="j-page-terms-conditions__section mb-6">
      <general-text dictionary-key="terms_and_conditions_changes_website_text" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_system_errors_title"
        class="j-text--main-gradient"
      />
    </div>
    <div class="j-page-terms-conditions__section mb-6">
      <general-text dictionary-key="terms_and_conditions_system_errors_text" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_omissions_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(errorsText, index )
        in addIndexForLocalization('terms_and_conditions_omissions_items_text')"
      :key="`errors_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :dictionary-key="errorsText.dictionaryKey" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_liability_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(liabilityText, index )
        in addIndexForLocalization('terms_and_conditions_liability_items_text')"
      :key="`liability_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :dictionary-key="liabilityText.dictionaryKey" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_violation_terms_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(violationText, index )
        in addIndexForLocalization('terms_and_conditions_violation_terms_items_text')"
      :key="`violation_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :dictionary-key="violationText.dictionaryKey" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_property_rights_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(propertyRightsText, index )
        in addIndexForLocalization('terms_and_conditions_property_rights_items_text')"
      :key="`property_rights_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :dictionary-key="propertyRightsText.dictionaryKey" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_personal_data_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(conditionPersonalText, index )
        in addIndexForLocalization('terms_and_conditions_personal_data_items_text')"
      :key="`condition_personal_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :dictionary-key="conditionPersonalText.dictionaryKey" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_marketing_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(marketingText, index )
        in addIndexForLocalization('terms_and_conditions_marketing_items_text')"
      :key="`marketing_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :dictionary-key="marketingText.dictionaryKey" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_cookie_title"
        class="j-text--main-gradient"
      />
    </div>
    <div class="j-page-terms-conditions__section mb-6">
      <general-text dictionary-key="terms_and_conditions_cookie_text" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_complaints_policy_title"
        class="j-text--main-gradient"
      />
    </div>
    <div
      v-for="(complaintsOneText, index) of complaintsPolicyText.partOneText"
      :key="`complaints_one_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :text="complaintsOneText" />
    </div>
    <div class="j-page-terms-conditions__section mb-6">
      <general-text-list
        :items-list="complaintsPolicyText.partOneTextList"
        text-list-items
      />
    </div>
    <div
      v-for="(complaintsTwoText, index) of complaintsPolicyText.partTwoText"
      :key="`complaints_two_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :text="complaintsTwoText" />
    </div>
    <div class="j-page-terms-conditions__section mb-6">
      <general-text-list
        :items-list="complaintsPolicyText.partTwoTextList"
        text-list-items
      />
    </div>
    <div
      v-for="(complaintsThreeText, index) of complaintsPolicyText.partThreeText"
      :key="`complaints_three_text_${index}`"
      class="j-page-terms-conditions__section mb-6"
    >
      <general-text :text="complaintsThreeText" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_transfer_authority_title"
        class="j-text--main-gradient"
      />
    </div>
    <div class="j-page-terms-conditions__section mb-6">
      <general-text dictionary-key="terms_and_conditions_transfer_authority_text" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_outstanding_force_title"
        class="j-text--main-gradient"
      />
    </div>
    <div class="j-page-terms-conditions__section mb-6">
      <general-text dictionary-key="terms_and_conditions_outstanding_force_items_text.0" />
    </div>
    <div class="j-page-terms-conditions__section mb-6">
      <general-text dictionary-key="terms_and_conditions_outstanding_force_items_text.1" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_renouncement_title"
        class="j-text--main-gradient"
      />
    </div>
    <div class="j-page-terms-conditions__section mb-6">
      <general-text dictionary-key="terms_and_conditions_renouncement_items_text.0" />
    </div>
    <div class="j-page-terms-conditions__section mb-6">
      <general-text dictionary-key="terms_and_conditions_renouncement_items_text.1" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_severability_agreement_title"
        class="j-text--main-gradient"
      />
    </div>
    <div class="j-page-terms-conditions__section mb-6">
      <general-text dictionary-key="terms_and_conditions_severability_agreement_text" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_jurisdiction_title"
        class="j-text--main-gradient"
      />
    </div>
    <div class="j-page-terms-conditions__section mb-6">
      <general-text dictionary-key="terms_and_conditions_jurisdiction_items_text.0" />
    </div>
    <div class="j-page-terms-conditions__section mb-6">
      <general-text dictionary-key="terms_and_conditions_jurisdiction_items_text.1" />
    </div>
    <div class="j-page-terms-conditions__section mt-12 mb-6">
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="terms_and_conditions_links_title"
        class="j-text--main-gradient"
      />
    </div>
    <div class="j-page-terms-conditions__section mb-6">
      <general-text dictionary-key="terms_and_conditions_links_text" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { jsPDF } from 'jspdf';
import { useI18n } from 'vue-i18n';
import { TEXT_TYPES } from '~/constants/general';
import {
  TABLES_ITEMS,
  GAMES_WAGERING_ONE_PERCENT,
} from '~/constants/staticPages';
import { ROBOTO_REGULAR_BASE_64 } from '~/pages/terms-and-conditions/constants';

const { t } = useI18n();

usePageMeta(t('terms_and_conditions_page_title'), t('terms_and_conditions_page_description'));

const printPage = () => {
  const printableContent = pdfSection.value?.innerHTML;
  const iframe = document.createElement('iframe');

  iframe.id = 'printFrame';
  iframe.style.display = 'none';
  document.body.appendChild(iframe);
  const doc = iframe?.contentWindow?.document;

  if (doc && printableContent) {
    doc.open();
    doc.write(printableContent);
    doc.close();
  }

  if (iframe) {
    iframe.contentWindow?.focus();
    iframe.contentWindow?.print();
  }

  setTimeout(() => {
    document.body.removeChild(iframe);
  }, 1000);
};

const downloadPage = async () => {
  if (pdfSection.value) {
    const pdf = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true,
    });

    pdf.addFileToVFS('Roboto-Regular-normal.ttf', ROBOTO_REGULAR_BASE_64);
    pdf.addFont('Roboto-Regular-normal.ttf', 'Roboto', 'normal');

    await pdf.html(pdfSection.value, {
      image: {
        type: 'jpeg',
        quality: 1,
      },
      margin: 5,
      html2canvas: {
        scale: 0.2,
        useCORS: true,
        // TS error because of wrong interface 'Html2CanvasOptions'
        onclone: (doc: Document, element: HTMLElement) => {
          element.classList.add('print-mode');
        },
      },
    });

    pdf.save('terms-and-conditions.pdf');
  }
};

const pdfSection = ref<HTMLElement | null>(null);
const bannedCountries = computed(() => JSON.parse(t('general_banned_countries')));
const strategicRestrictionCountries = computed(() => JSON.parse(t('general_strategic_restriction_countries')));

const amlPolicyText = computed( () => {
  const partOneText = JSON.parse(t('terms_and_conditions_kyc_policy_text_part_one'));
  const partOneTextList = JSON.parse(t('terms_and_conditions_kyc_policy_identification_text_list_part_one'));
  const partTwoText = JSON.parse(t('terms_and_conditions_kyc_policy_text_part_two'));
  const partTwoTextList = JSON.parse(t('terms_and_conditions_kyc_policy_transactions_text_list_part_two'));
  const partThreeTextList = JSON.parse(t('terms_and_conditions_kyc_policy_transactions_text_list_part_three'));
  const partFourText = JSON.parse(t('terms_and_conditions_kyc_policy_text_part_four'));
  const partFiveText = JSON.parse(t('terms_and_conditions_kyc_policy_text_part_five'));

  return {
    partOneText,
    partOneTextList,
    partTwoText,
    partTwoTextList,
    partThreeTextList,
    partFourText,
    partFiveText,
  };
});

const paymentPolicyText = computed(() => {
  const textOne = JSON.parse(t('terms_and_conditions_payment_policy_text_part_one'));
  const textTwo = JSON.parse(t('terms_and_conditions_payment_policy_text_part_two'));

  return {
    textOne,
    textTwo,
  };
});

const bonusPolicyText = computed( () => {
  const partOneText = JSON.parse(t('terms_and_conditions_bonus_policy_text_part_one'));
  const partTwoText = JSON.parse(t('terms_and_conditions_bonus_policy_text_part_two'));
  const partThreeText = JSON.parse(t('terms_and_conditions_bonus_policy_text_part_three'));
  const partThreeTextList = JSON.parse(t('terms_and_conditions_bonus_policy_text_list_part_three'));
  const partFiveText = JSON.parse(t('terms_and_conditions_bonus_policy_text_part_five'));

  return {
    partOneText,
    partTwoText,
    partThreeText,
    partThreeTextList,
    partFiveText,
  };
});

const complaintsPolicyText = computed( () => {
  const partOneText = JSON.parse(t('terms_and_conditions_complaints_policy_general_text_part_one'));
  const partOneTextList = JSON.parse(t('terms_and_conditions_complaints_policy_general_text_list_part_one'));
  const partTwoText = JSON.parse(t('terms_and_conditions_complaints_policy_general_text_part_two'));
  const partTwoTextList = JSON.parse(t('terms_and_conditions_complaints_policy_general_text_list_part_two'));
  const partThreeText = JSON.parse(t('terms_and_conditions_complaints_policy_general_text_part_three'));

  return {
    partOneText,
    partOneTextList,
    partTwoText,
    partTwoTextList,
    partThreeText,
  };
});

const isMobile = inject<Ref<boolean>>('isMobile');
const columnCountForMobile = computed(() => isMobile?.value
  ? '1'
  : '2',
);
</script>
